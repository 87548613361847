* {
  box-sizing: border-box;
  font-size: 14px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

header {
  display: flex;
  justify-content: center;
  padding: 2em 0 0.5em;
}

main {
  align-items: center;
  display: flex;
  flex-flow: column;
  padding: 0.5em 0;
}

h1 {
  font-size: 24px;
  font-weight: 400;
  margin: 0;
  padding-bottom: 0.5em;
  text-align: center;
}

p {
  color: #54585d;
  line-height: 1.8;
  margin: 0 0 2em;
  max-width: 500px;
  text-align: center;
}

footer {
  display: flex;
  justify-content: center;
  padding: 2em 0 1em;
}

footer a {
  color: #414a53;
  padding: 0.5em;
  text-align: center;
  text-decoration: none;
}

footer a:visited {
  color: #414a53;
}

footer a:hover {
  color: #035ec7;
  text-decoration: underline;
}
